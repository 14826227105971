var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"move-outside",rawName:"v-move-outside"}],attrs:{"title":_vm.$t('customerInfo.Thali detail'),"visible":_vm.isShow,"width":"400px","append-to-body":"","before-close":_vm.close,"custom-class":"forwarderDialog"},on:{"update:visible":function($event){_vm.isShow=$event}}},[(_vm.companyThaliDetail)?_c('div',{staticClass:"headerBox",attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("customerInfo.Thali detail")))]),(_vm.companyThaliDetail.currentCombo.comboEndTime)?_c('div',{staticClass:"time"},[(!_vm.judgeTime(_vm.companyThaliDetail.currentCombo.comboEndTime))?_c('span',[_vm._v(_vm._s(_vm.$dayjs(_vm.companyThaliDetail.currentCombo.comboEndTime).format( "YYYY/MM/DD" ))+" "+_vm._s(_vm.$t("customerInfo.become due")))]):_c('span',{staticStyle:{"color":"#DC3545"}},[_vm._v(_vm._s(_vm.$tc('customerInfo.ExpiredDay', _vm.DateDiff(_vm.$dayjs(_vm.companyThaliDetail.currentCombo.comboEndTime).format("YYYY-MM-DD"), _vm.$dayjs().format("YYYY-MM-DD")))))])]):_vm._e()]):_vm._e(),(_vm.companyThaliDetail)?_c('div',{staticClass:"thaliDtailBox"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"label"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("customerInfo.customerNumber")))]),_c('div',{staticClass:"useNumber"},[_vm._v(" "+_vm._s(_vm.$t("customerInfo.Used"))+" "),_c('span',[_vm._v(_vm._s(_vm.companyThaliDetail.usedCombo.customerNumber))]),_vm._v("/"+_vm._s(_vm.companyThaliDetail.currentCombo.customerNumber)+" ")])]),_c('div',{staticClass:"value"},[_c('el-progress',{attrs:{"color":"#004C97","percentage":_vm.$toPercent(
              _vm.companyThaliDetail.usedCombo.customerNumber,
              _vm.companyThaliDetail.currentCombo.customerNumber
            ),"stroke-width":12,"show-text":false}})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"label"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("customerInfo.storageSize")))]),_c('div',{staticClass:"useNumber"},[_vm._v(" "+_vm._s(_vm.$t("customerInfo.Used"))+" "),_c('span',[_vm._v(_vm._s(_vm.$formatFileSize(_vm.companyThaliDetail.usedCombo.storageSize)))]),_vm._v("/"+_vm._s(_vm.companyThaliDetail.currentCombo.storageSize)+"GB ")])]),_c('div',{staticClass:"value"},[_c('el-progress',{attrs:{"color":"#004C97","percentage":_vm.$toPercent(
              _vm.companyThaliDetail.usedCombo.storageSize / 1024 / 1024,
              _vm.companyThaliDetail.currentCombo.storageSize
            ),"stroke-width":12,"show-text":false}})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"label"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("customerInfo.memberNumber")))]),_c('div',{staticClass:"useNumber"},[_vm._v(" "+_vm._s(_vm.$t("customerInfo.Used"))+" "),_c('span',[_vm._v(_vm._s(_vm.companyThaliDetail.usedCombo.memberNumber))]),_vm._v("/"+_vm._s(_vm.companyThaliDetail.currentCombo.memberNumber)+" ")])]),_c('div',{staticClass:"value"},[_c('el-progress',{attrs:{"color":"#004C97","percentage":_vm.$toPercent(
              _vm.companyThaliDetail.usedCombo.memberNumber,
              _vm.companyThaliDetail.currentCombo.memberNumber
            ),"stroke-width":12,"show-text":false}})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"label"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("customerInfo.orderNumber")))]),_c('div',{staticClass:"useNumber"},[_vm._v(" "+_vm._s(_vm.$t("customerInfo.Used"))+" "),_c('span',[_vm._v(_vm._s(_vm.companyThaliDetail.usedCombo.orderNumber))]),(_vm.companyCurrentType != 0 && _vm.companyCurrentType != 2)?[_vm._v("/"+_vm._s(_vm.companyThaliDetail.currentCombo.orderNumber))]:_vm._e()],2)]),_c('div',{staticClass:"value"},[_c('el-progress',{attrs:{"color":"#004C97","percentage":_vm.$toPercent(
              _vm.companyThaliDetail.usedCombo.orderNumber,
              _vm.companyThaliDetail.currentCombo.orderNumber
            ),"stroke-width":12,"show-text":false}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }