<template>
  <el-dialog
    :title="$t('customerInfo.Thali detail')"
    :visible.sync="isShow"
    width="400px"
    append-to-body
    :before-close="close"
    custom-class="forwarderDialog"
    v-move-outside
  >
    <div class="headerBox" slot="title" v-if="companyThaliDetail">
      <div class="title">{{ $t("customerInfo.Thali detail") }}</div>
      <div class="time" v-if="companyThaliDetail.currentCombo.comboEndTime">
        <span v-if="!judgeTime(companyThaliDetail.currentCombo.comboEndTime)"
          >{{
            $dayjs(companyThaliDetail.currentCombo.comboEndTime).format(
              "YYYY/MM/DD"
            )
          }}
          {{ $t("customerInfo.become due") }}</span
        >
        <span style="color: #DC3545;" v-else>{{ $tc('customerInfo.ExpiredDay', DateDiff($dayjs(companyThaliDetail.currentCombo.comboEndTime).format("YYYY-MM-DD"), $dayjs().format("YYYY-MM-DD"))) }}</span>
      </div>
    </div>
    <div class="thaliDtailBox" v-if="companyThaliDetail">
      <div class="item">
        <div class="label">
          <div class="title">{{ $t("customerInfo.customerNumber") }}</div>
          <div class="useNumber">
            {{ $t("customerInfo.Used") }}
            <span>{{ companyThaliDetail.usedCombo.customerNumber }}</span
            >/{{ companyThaliDetail.currentCombo.customerNumber }}
          </div>
        </div>
        <div class="value">
          <el-progress
            color="#004C97"
            :percentage="
              $toPercent(
                companyThaliDetail.usedCombo.customerNumber,
                companyThaliDetail.currentCombo.customerNumber
              )
            "
            :stroke-width="12"
            :show-text="false"
          ></el-progress>
        </div>
      </div>
      <div class="item">
        <div class="label">
          <div class="title">{{ $t("customerInfo.storageSize") }}</div>
          <div class="useNumber">
            {{ $t("customerInfo.Used") }}
            <span>{{
              $formatFileSize(companyThaliDetail.usedCombo.storageSize)
            }}</span
            >/{{ companyThaliDetail.currentCombo.storageSize }}GB
          </div>
        </div>
        <div class="value">
          <el-progress
            color="#004C97"
            :percentage="
              $toPercent(
                companyThaliDetail.usedCombo.storageSize / 1024 / 1024,
                companyThaliDetail.currentCombo.storageSize
              )
            "
            :stroke-width="12"
            :show-text="false"
          ></el-progress>
        </div>
      </div>
      <div class="item">
        <div class="label">
          <div class="title">{{ $t("customerInfo.memberNumber") }}</div>
          <div class="useNumber">
            {{ $t("customerInfo.Used") }}
            <span>{{ companyThaliDetail.usedCombo.memberNumber }}</span
            >/{{ companyThaliDetail.currentCombo.memberNumber }}
          </div>
        </div>
        <div class="value">
          <el-progress
            color="#004C97"
            :percentage="
              $toPercent(
                companyThaliDetail.usedCombo.memberNumber,
                companyThaliDetail.currentCombo.memberNumber
              )
            "
            :stroke-width="12"
            :show-text="false"
          ></el-progress>
        </div>
      </div>
      <!--  -->
      <div class="item">
        <div class="label">
          <div class="title">{{ $t("customerInfo.orderNumber") }}</div>
          <div class="useNumber">
            {{ $t("customerInfo.Used") }}
            <span>{{ companyThaliDetail.usedCombo.orderNumber }}</span
            ><template v-if="companyCurrentType != 0 && companyCurrentType != 2"
              >/{{ companyThaliDetail.currentCombo.orderNumber }}</template
            >
          </div>
        </div>
        <div class="value">
          <el-progress
            color="#004C97"
            :percentage="
              $toPercent(
                companyThaliDetail.usedCombo.orderNumber,
                companyThaliDetail.currentCombo.orderNumber
              )
            "
            :stroke-width="12"
            :show-text="false"
          ></el-progress>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
export default {
  name: "seeThaliDetail",
  data() {
    return {
      isShow: false,
      companyThaliDetail: null,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["companyCurrentType"]),
  },
  methods: {
    open(thali) {
      this.isShow = true;
      this.companyThaliDetail = thali;
    },
    close() {
      this.isShow = false;
    },
    judgeTime(time) {
      var strtime = time.replace("/-/g", "/"); //时间转换
      //时间
      var date1 = new Date(strtime);
      //现在时间
      var date2 = new Date();
      //判断时间是否过期
      return date1 < date2 ? true : false;
    },
    DateDiff(sDate1, sDate2) {
      //sDate1和sDate2是2006-12-18格式
      var aDate1, aDate2, oDate1, oDate2, iDays;
      aDate1 = sDate1.split("-");
      oDate1 = new Date(aDate1[1] + "/" + aDate1[2] + "/" + aDate1[0]);
      aDate2 = sDate2.split("-");
      oDate2 = new Date(aDate2[1] + "/" + aDate2[2] + "/" + aDate2[0]);
      iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24);
      return iDays;
    },
  },
};
</script>

<style lang="less" scoped>
.headerBox {
  display: flex;
  gap: 16px;
  align-items: center;
  .time {
    font-size: 12px;
    color: #637381;
  }
}
/deep/ .el-dialog__body {
  .thaliDtailBox {
    margin-top: 10px;
    .item {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 20px;
      }
      .label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .title {
          font-size: 14px;
          color: #637381;
          line-height: 20px;
        }
        .useNumber {
          font-size: 12px;
          color: #637381;
          line-height: 16.8px;
          span {
            color: #122545;
            font-size: 14px;
            font-weight: 600;
            margin-left: 2px;
          }
        }
      }
    }
  }
}
</style>
